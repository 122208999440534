import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { ThanksModal } from "../components/ThanksModal";
import { QuestionForm } from "../QuestionForm";
import { AuthModal } from "../components/AuthModal";
import { validateIfPreviouslySubmmitForm } from "../service/auth.service";
export const Home = () => {
  const navigate = useNavigate();
  const [session, setSession] = useState(null);
  const [seeThanksModal, setSeeTanksModal] = useState(false);

  useEffect(() => {
    const getUserSession = async () => {
      const isPreviouslySubmmitedForm = await validateIfPreviouslySubmmitForm(
        session
      );
      if (isPreviouslySubmmitedForm) {
        navigate("/sorry");
      }
    };
    getUserSession();
  }, [session]);

  const handleDialog = (state) => {
    setSeeTanksModal(state);
    if (!state) {
      navigate("/sorry");
    }
  };
  return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        <h1 className="text-3xl font-bold mb-6">
          Cuestionario de lógica y conocimiento general
        </h1>
      </header>
      {seeThanksModal && <ThanksModal handleDialog={handleDialog} />}
      {session && (
        <QuestionForm session={session} handleDialog={handleDialog} />
      )}
      {!session && <AuthModal session={session} setSession={setSession} />}
    </div>
  );
};
