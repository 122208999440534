import React from "react";

export const ConfirmationModal = ({ onConfirm, onCancel }) => {
  const handleDialog = () => {

  };
  return (
    <dialog
      open
      className="w-full h-full backdrop-blur-sm bg-black/30 flex items-center justify-center fixed top-0"
    >
      <div className="w-full px-4 py-8 max-w-sm bg-gray-800 text-white flex flex-col sm:rounded-lg relative">
        <h1 className="text-2xl font-bold text-start">¿Estás Seguro?</h1>
        <span className="font-light text-sm text-start text-pretty text-white/70">
        Estás a punto de pasar a la siguiente pregunta sin haber marcado una respuesta. No se podrá volver atrás después de continuar.
        </span>
        <div className="flex w-full justify-end gap-2 mt-4">
          <button onClick={onCancel} className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold text-sm py-2 px-4 rounded-lg transition-colors duration-200">Cancelar</button>
          <button onClick={onConfirm} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold text-sm py-2 px-4 rounded-lg transition-colors duration-200">Continuar</button>
          </div>
      </div>
    </dialog>
  );
};
