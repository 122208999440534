import { supabase } from "../supabaseClient";

export const postUserResponse = async (payload) => {
  return await supabase
    .from("user_answer")
    .insert(payload)
    .select();
  
};

export const generateResponse = (arrResponse, questions, user) => {
  const cleanedData = arrResponse.map(({ part, question, answer, time_remaining }, index) => {
    return {
      username: user.user_metadata.name,
      mail: user.user_metadata.email,
      question_type: questions[part-1].part,
      question: questions[part-1].questions[question-1].question,
      time_remaining: time_remaining,
      answer: answer
    }
  });
  
  return cleanedData;
};
