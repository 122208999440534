import React, { useState, useEffect } from "react";
import chartImage from "./assets/image.png"
import questions from "./questions.json";
import { generateResponse, postUserResponse } from "./service/answer.service";
import { ConfirmationModal } from "./components/ConfirmationModal";
import { loadingNotify, notify, stopNotify } from "./utils/toastNotify";


export const QuestionForm = ({ session, handleDialog }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [timer, setTimer] = useState(45);
  const [answers, setAnswers] = useState(
    Array.from({ length: questions.length }, () => [])
  );
  const [timesRemaining, setTimesRemaining] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [timeBeforeModal, setTimeBeforeModal] = useState(0);

  useEffect(() => {
    if (timer > 0) {
      const timerId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      handleNextQuestion();
    }
  }, [timer]);

  const handleNextQuestion = async () => {
    if (!selectedOption && timer > 0) {
      setTimeBeforeModal(timer);
      setPendingAction(() => proceedToNextQuestion);
      setIsModalVisible(true);
      return;
    }
    if (!isModalVisible) {
      await proceedToNextQuestion();
    }
  };

  const proceedToNextQuestion = async () => {
    const timeRemaining = [...timesRemaining, timer];
    setTimesRemaining(timeRemaining);
    const totalQuestionsInPart = questions[currentPartIndex].questions.length;

    if (currentQuestionIndex < totalQuestionsInPart - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentPartIndex < questions.length - 1) {
      setCurrentPartIndex(currentPartIndex + 1);
      setCurrentQuestionIndex(0);
    } else {
      await saveResponses();
      handleDialog(true);
    }
    setTimer(45);
    setSelectedOption(null);
  };

  const handleAnswerChange = (option) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentPartIndex][currentQuestionIndex] = option;
    setAnswers(updatedAnswers);
    setSelectedOption(option);
  };

  const saveResponses = async () => {
    const notifyId = loadingNotify('Enviando cuestionario')
    const responseData = answers.flat().map((answer, index) => ({
      part: Math.floor(index / 10) + 1,
      question: (index % 10) + 1,
      answer,
      time_remaining: timesRemaining[index],
    }));

    const cleanedData = generateResponse(responseData, questions, session);
    const {data, error} =await postUserResponse(cleanedData);
    if (error) {
      notify('Error al cargar el cuestionario, por favor vuelva a intentarlo de nuevo más tarde', 'error');
    }else{
      notify('Cuestionario cargado correctamente!', 'success');
    }
    stopNotify(notifyId);
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false);
    await pendingAction();
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setTimer(timeBeforeModal); 
  };

  return (
    <div className="flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4">
        Parte {currentPartIndex + 1}: {questions[currentPartIndex].part}
      </h2>
      <h3 className="text-xl mb-2">Pregunta {currentQuestionIndex + 1}</h3>
      <p className="mb-4">
        {questions[currentPartIndex].questions[currentQuestionIndex].question}
      </p>
      {
        questions[currentPartIndex].questions[currentQuestionIndex].question === "Observa el siguiente gráfico y responde: ¿Qué mes tuvo las ventas más altas?" &&
        <img src={chartImage} className="mb-4" />
      }
      <div className="mb-4 flex flex-col gap-2">
        {questions[currentPartIndex].questions[
          currentQuestionIndex
        ].options.map((option, index) => (
          <button
            key={index}
            type="button"
            value={option}
            name={`question${currentPartIndex}-${currentQuestionIndex}`}
            onClick={() => handleAnswerChange(option)}
            className={`border border-solid border-black/30 rounded-sm min-w-32 px-4 py-2 transition ${
              selectedOption === option
                ? "bg-blue-500 text-white"
                : "bg-white text-black"
            }`}
          >
            {option}
          </button>
        ))}
      </div>
      <p className="mb-4">Tiempo restante: {timer} segundos</p>
      <button
        onClick={handleNextQuestion}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
      >
        Siguiente
      </button>
      {isModalVisible && (
        <ConfirmationModal
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          message="No has seleccionado ninguna respuesta. ¿Deseas pasar a la siguiente pregunta?"
        />
      )}
    </div>
  );
};
