import { useState } from "react";

export const AuthForm = ({ onSubmit, type, onCancel }) => {
  const [seePassword, setSeePassword] = useState(false);
  const initialValues =
    type === "register"
      ? { password: "", email: "", name: "" }
      : { password: "", email: "" };
  const [formState, setFormState] = useState(initialValues);
  const handleForm = (e) => {
    const { name, value } = e.target;
    setFormState((prevValue) => ({ ...prevValue, [name]: value }));
  };
  const handleSeePassword = () => {
    setSeePassword(!seePassword);
  };
  return (
    <div className="backdrop-blur-sm bg-black/30 w-full h-screen flex items-center justify-center fixed top-0 left-0">
      <form
        onSubmit={(e) => onSubmit(e, formState)}
        className=" bg-[#282c34] flex flex-col border border-solid border-gray-700 rounded-lg p-5 gap-3.5"
      >
        {type === "register" && (
          <input
            onChange={handleForm}
            type="text"
            className="w-full px-4 py-2 text-sm text-gray-200 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            name="name"
            required
            placeholder="Por favor inserte el nombre"
            value={formState.name ?? ""}
          />
        )}
        <input
          onChange={handleForm}
          type="email"
          className="w-full px-4 py-2 text-sm text-gray-200 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          name="email"
          required
          placeholder="Por favor inserte el correo"
          value={formState.email ?? ""}
        />

        <div className="relative flex">
          <input
            onChange={handleForm}
            type={seePassword ? "text" : "password"}
            className="w-full pl-4 pr-8 py-2 text-sm text-gray-200 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            name="password"
            required
            placeholder="Por favor inserte la contraseña"
            value={formState.password ?? ""}
          />
          <button
            type="button"
            className="absolute top-1/2 right-1 -translate-y-1/2 flex items-center justify-center"
            onClick={handleSeePassword}
          >
            {seePassword ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-eye-off"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                <path d="M3 3l18 18" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-eye"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
              </svg>
            )}
          </button>
        </div>
        <div className="flex w-full justify-end gap-2 mt-4">

        <button onClick={onCancel} className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold text-sm py-2 px-4 rounded-lg transition-colors duration-200">Cancelar</button>
        <button className="w-8/12 mx-auto py-2 px-4 bg-blue-600 text-lg hover:bg-blue-700 text-white font-semibold rounded-md transition duration-300 ease-in-out">
          {type === "register" ? "Registrame" : "Iniciar Sesión"}
        </button>
        </div>
      </form>
    </div>
  );
};
