import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import 'react-toastify/dist/ReactToastify.css';

import { SubmmitedForm } from "./routes/SubmmitedForm";
import { Home } from "./routes/Home";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sorry" element={<SubmmitedForm />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
