import React from "react";

export const ThanksModal = ({ handleDialog }) => {
  return (
    <dialog
      open
      className="w-full h-full backdrop-blur-sm bg-black/30 flex items-center justify-center"
    >
      <div className="w-full px-4 py-8 max-w-sm bg-gray-800 text-white flex flex-col sm:rounded-lg relative">
        <button
          className="absolute right-2.5 top-2.5"
          onClick={() => handleDialog(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-x"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </button>
        <h1 className="text-2xl font-bold text-start">¡Gracias!</h1>
        <span className="font-light text-base text-start text-pretty">
          Agradecemos que hayas completado el formulario.
        </span>
        <p className="font-light text-base text-start text-pretty">
          Tu información ha sido recibida con éxito. <br /> Nos pondremos en
          contacto contigo pronto.
        </p>
      </div>
    </dialog>
  );
};
