import { supabase } from "../supabaseClient";

export const authWithGoogle = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "google",
  });

  if (error) {
    console.error("Error during sign in:", error.message);
    return null;
  }

  return data;
};

export const getUserAutenticatedWithGoogle = async () => {
  try {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    return user;
  } catch (error) {
    console.log(error);
    throw "error to get User";
  }
};

export const validateIfPreviouslySubmmitForm = async (user) => {
  try {
    let { data, error } = await supabase
      .from("user_answer")
      .select("*")
      .eq("mail", user.email);

    if (data.length > 0) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const singUp = async ({ name, email, password }) => {
  console.log('Registrandome');
  
  return await supabase.auth.signUp({
    password,
    email,
    options: {
      data: { name },
    },
  });
};

export const logIn = async ({ email, password }) => {
  return await supabase.auth.signInWithPassword({
    email,
    password,
  });
};
