import React, { useState } from "react";
import { logIn, singUp } from "../service/auth.service";
import { AuthForm } from "./AuthForm";
import { loadingNotify, notify, stopNotify } from "../utils/toastNotify";
export const AuthModal = ({ setSession }) => {
  const [type, setType] = useState("");
  const [seeForm, setSeeForm] = useState(false);
  const handleAuthForm = async (type) => {
    setSeeForm(true);
    setType(type);
  };

  const onCancel = ()=>{
    setSeeForm(false);
  }
  
  const onSubmit = async (e, formState) => {
    e.preventDefault();
    const notifyId = loadingNotify("Enviando formulario");
    try {
      if (type === "register") {
        const { data, error } = await singUp(formState);
        if (error) {
          throw error;
        } else {
          notify(
            "Usuario registrado con éxito!",
            "success"
          );

          setSession(data.user);
        }
      } else {
        const { data, error } = await logIn(formState);
        if (error) {
          throw error;
        } else {
          notify(
            "Usuario logueado con éxito!",
            "success"
          );
          setSession(data.user);
        }
      }
    } catch (error) {
      notify(
        "Error al completar la solicitud. Por favor intentelo de nuevo más tarde",
        "error"
      );
      console.log(error);
    } finally{
      stopNotify(notifyId);
    }
  };

  return (
    <section className="p-6 border border-black/30 rounded-lg shadow-md shadow-black/30">
      <h2 className="mb-4 font-light">
        Antes de continuar por favor inicia sesión o regístrate
      </h2>
      <div className="flex w-full gap-2 items-center justify-center">
        <button
          className="py-2 px-4 border border-white/30 rounded-lg shadow-sm shadow-white/30 focus:border-white/70"
          onClick={() => handleAuthForm("login")}
        >
          Iniciar Sesión
        </button>
        <button
          className="py-2 px-4 border border-white/30 rounded-lg shadow-sm shadow-white/30 focus:border-white/70"
          onClick={() => handleAuthForm("register")}
        >
          Registrarme
        </button>
      </div>
      {seeForm && <AuthForm type={type} onCancel={onCancel} onSubmit={onSubmit} />}
    </section>
  );
};
